<template>
  <section>
    <div v-if="images.length > 0">
      <image-collection
        class="flex justify-center -mt-6 mb-6"
        :images="images"
      />
    </div>
    <div>
      <rich-text-field
        class="mt-4"
        :hideBorder="true"
        :showEditor="false"
        v-model="text"
      />
    </div>
  </section>
</template>

<script>
import RichTextField from '../../../../components/form/RichTextField';
import ImageCollection from '../ImageCollectionComponent';
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      default: () => []
    }
  },

  components: {
    RichTextField,
    ImageCollection
  }
};
</script>

<style></style>
